import ReactGA from 'react-ga';
import styled from 'styled-components';
import { useEffect } from 'react';

const BOND_4_CLASSES =
    'https://app.harbiz.io/checkout-form/aguadopilates?addon=P4fvuwzoryGajuL2Q';
const BOND_10_CLASSES =
    'https://app.harbiz.io/checkout-form/aguadopilates?addon=DbtqPRbWpsP5KfL7h';

export const BondsPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Wrapper>
            <h2 className="title__workout">Bonos</h2>
            <section className="description">
                <div>
                    <h5 className="description_title">
                        Si necesitas
                        <span
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            {' '}
                            FLEXIBILIDAD{' '}
                        </span>
                        debido a horarios variables,
                        <span
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            {' '}
                            ESTO ES PARA TI{' '}
                        </span>
                    </h5>
                    <h5 className="description_title">
                        <span
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            {' '}
                            DISFRUTA{' '}
                        </span>
                        de una experiencia integral con la guía y el apoyo de tu
                        instructor
                    </h5>
                    <h5 className="description_title">
                        <span
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            {' '}
                            PLANIFICA Y PROGRAMA{' '}
                        </span>
                        tus clases con anticipación para asegurarte de mantener
                        una rutina constante y equilibrada
                    </h5>
                    <h5 className="description_title">
                        <span
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            {' '}
                            COMPROMETETE{' '}
                        </span>
                        con tu salud y bienestar adquiriendo el bono que mejor
                        se adapte a ti
                    </h5>
                    <h5 className="description_title">
                        ¡
                        <span
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            RESERVA{' '}
                        </span>
                        tu primera clase!
                    </h5>
                </div>
            </section>

            <section className="images">
                <div className="img_wrapper">
                    <img
                        src="./img/bonds/bond_4_classes.png"
                        alt="bond-4-classes"
                        className="img_bond"
                    />
                    <div className="data">
                        <a
                            href={BOND_4_CLASSES}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button
                                style={{
                                    marginBottom: '0px',
                                }}
                            >
                                COMPRAR
                            </button>
                        </a>
                    </div>
                </div>
                <div className="img_wrapper">
                    <img
                        src="./img/bonds/bond_10_classes.png"
                        alt="bond-10-classes"
                        className="img_bond"
                    />
                    <div className="data">
                        <a
                            href={BOND_10_CLASSES}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button
                                style={{
                                    marginBottom: '0px',
                                }}
                            >
                                COMPRAR
                            </button>
                        </a>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
};

export const Wrapper = styled.div`
    margin-bottom: 2rem;
    padding: 8px;

    .title__workout {
        text-align: center;
        font-size: 2rem;
    }

    .img_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .description {
        text-align: center;
    }

    .description_title {
        font-weight: 400;
        font-size: 1rem;
    }

    .images {
        margin-top: 40px;
        display: flex;
        gap: 4rem;
        justify-content: center;
        align-items: center;
    }

    .img_bond {
        width: 400px;
    }

    button {
        margin: 48px 0px 32px 0px;
        background-color: #4a4a4a;
        border-radius: 40px;
        color: white;
        border: none;
        width: 150px;
        height: 35px;
        cursor: pointer;
    }

    button:hover {
        background-color: #b9afa1;
    }

    @media (max-width: 800px) {
        .title__workout {
            font-size: 1.5rem;
        }

        .images {
            flex-direction: column;
        }

        .img_bond {
            width: 280px;
        }
    }
`;
