import './footer.css';
import { Link } from 'react-router-dom';

export function Footer() {
    return (
        <>
            <footer>
                <img
                    className="footer__logo"
                    src="./img/footer/logofooter.svg"
                    alt="Logo Aguado pilates"
                />
                <a
                    href="https://www.instagram.com/aguado.pilates/?hl=es"
                    target="_blank"
                    rel="noreferrer"
                    className="warapperInstagram"
                >
                    <p className="instagram">- Síguenos en Instagram -</p>
                </a>

                <div className="copyright">
                    <p className="copyright__ap">© AGUADO PILATES | 2023</p>

                    <div className="legal">
                        <Link
                            to="/privacy-policy"
                            className="legal__link"
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                                color: 'white',
                            }}
                        >
                            Política de privacidad
                        </Link>
                        |
                        <Link
                            to="/terms-and-conditions"
                            className="legal__link"
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                                color: 'white',
                            }}
                        >
                            Térmimos y condiciones
                        </Link>
                    </div>
                </div>
            </footer>
        </>
    );
}
