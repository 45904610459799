import { Wrapper } from './allWorkoutsPage.style';
import { T } from '../../text';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

const LINK_PILATES =
    'https://app.harbiz.io/checkout-form/aguadopilates?product=QwaAMPxjhcydxYd6R';

const LINK_HIPRESIVOS =
    'https://app.harbiz.io/checkout-form/aguadopilates?product=GFcm4Wb6Wu4gGY5v7';

const LINK_PILATES_HIPRESIVOS =
    'https://app.harbiz.io/checkout-form/aguadopilates?product=w3b45WmLmbGRMszAo';

const LINK_CHALLENGE =
    'https://app.harbiz.io/checkout-form/aguadopilates?addon=xorJdmhpmRBerCHpb&lang=es';

export function AllWorkoutsPage() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Wrapper>
            <main className="containerWorkoutPage">
                <h2 className="title__workout">Suscripciones</h2>

                {/* <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '3rem',
                    }}
                >
                    <h3 className="workout-title">PILATES & ALIMENTACIÓN</h3>
                    <div
                        style={{
                            display: 'flex',
                            gap: '2rem',
                        }}
                        className="wrapper__challenge"
                    >
                        <img
                            className="img_challenge"
                            src="./img/allWorkout/challange.jpg"
                            alt=""
                        />
                        <span className="paragraph_workout">
                            <h4>VERME BIEN, COMER BIEN, SENTIRME BIEN</h4>
                            <div className="data">
                                <h4>PILATES</h4>
                                <ul>
                                    <li>4 semanas</li>
                                    <li>12 entrenamientos de 15-20 min</li>
                                    <li>2 clases en DIRECTO a la semana</li>
                                </ul>

                                <h4>ALIMENTACIÓN</h4>
                                <ul>
                                    <li>
                                        Planificación de comidas equilibradas,
                                        saludables y con recetas detalladas.
                                    </li>
                                </ul>

                                <h4>MOTIVACIÓN</h4>
                                <ul>
                                    <li>
                                        Contacto directo y continuo con vuestra
                                        instructora ¡Hacerlo en equipo hará
                                        fácil el camino!
                                    </li>
                                </ul>

                                <h3>PAGO 30€</h3>
                            </div>
                            <a
                                href={LINK_CHALLENGE}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <button
                                    className="button-custom"
                                    style={{
                                        width: 'fit-content',
                                        padding: '0rem 1rem',
                                    }}
                                >
                                    ¡Unirme al reto!
                                </button>
                            </a>
                        </span>
                    </div>
                </div> */}

                <div className="wrapperWorkouts">
                    <section className="workout-line">
                        <div className="workout">
                            <h3 className="workout-title">PILATES</h3>
                            <img
                                className="img"
                                src="./img/allWorkout/pilates.jpg"
                                alt=""
                            />
                            <span className="paragraph_workout">
                                <h4>¡ALCANZA TU MEJOR VERSIÓN!</h4>
                                <div className="data">
                                    <p>
                                        Un cuerpo fuerte, resistente y flexible
                                        te permitirá vivir más y mejor.
                                    </p>
                                    <p>
                                        Clases lunes y miércoles de 20:00 a
                                        21:00hrs.
                                    </p>
                                    <h3>PAGO 20€/MES.</h3>
                                </div>
                                <a
                                    href={LINK_PILATES}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <button
                                        className="button-custom"
                                        style={{
                                            width: 'fit-content',
                                            padding: '0rem 1rem',
                                        }}
                                    >
                                        ¡Empezar ya!
                                    </button>
                                </a>
                            </span>
                        </div>
                        <div className="workout">
                            <h3 className="workout-title">HIPOPRESIVOS</h3>
                            <img
                                className="img"
                                src="./img/allWorkout/hipopresivos.jpg"
                                alt=""
                            />
                            <span className="paragraph_workout">
                                <h4>¡DISFRUTA DEL AUTÉNTICO BIENESTAR!</h4>
                                <div className="data">
                                    <p>
                                        Fortalece tu abdomen, mejora tu postura
                                        y consigue una cintura más estrecha.
                                    </p>
                                    <p>
                                        Clases martes y jueves de 7:30h a 8:30h
                                        / 8:00 a 8:30hrs.
                                    </p>
                                    <h3>PAGO 30€/MES.</h3>
                                </div>
                                <a
                                    href={LINK_HIPRESIVOS}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <button
                                        className="button-custom"
                                        style={{
                                            width: 'fit-content',
                                            padding: '0rem 1rem',
                                        }}
                                    >
                                        ¡Empezar ya!
                                    </button>
                                </a>
                            </span>
                        </div>
                    </section>
                    <section className="workout-line">
                        <div className="workout">
                            <h3 className="workout-title">
                                PILATES & HIPOPRESIVOS
                            </h3>
                            <img
                                className="img"
                                src="./img/allWorkout/pilatesehipopresivos.jpg"
                                alt=""
                            />

                            <span className="paragraph_workout">
                                <h4>¡LA RUTINA PERFECTA!</h4>
                                <div className="data">
                                    <p>
                                        Benefíciate de todo lo que nos aportan
                                        ambas disciplinas.
                                    </p>
                                    <p>Clases lunes y miércoles.</p>
                                    <h3>PAGO 45€/MES.</h3>
                                </div>
                                <a
                                    href={LINK_PILATES_HIPRESIVOS}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <button
                                        className="button-custom"
                                        style={{
                                            width: 'fit-content',
                                            padding: '0rem 1rem',
                                        }}
                                    >
                                        ¡Empezar ya!
                                    </button>
                                </a>
                            </span>
                        </div>
                        <div className="workout">
                            <h3 className="workout-title">
                                CLASES PERSONALIZADAS
                            </h3>

                            <img
                                className="img"
                                src="./img/allWorkout/clasesPersonalizadas.jpg"
                                alt=""
                            />

                            <span className="paragraph_workout">
                                <h4>¡ADAPTAMOS LA CLASE A TI!</h4>
                                <div className="data">
                                    <p>
                                        Clases creadas con objetivos muy
                                        concretos y personales.
                                    </p>
                                    <p>Horario flexible.</p>
                                    <h3>¡CONTACTA CONMIGO!</h3>
                                </div>

                                <button className="button-custom button-sold-out">
                                    {T.SOLD_OUT}
                                </button>
                            </span>
                        </div>
                    </section>
                    <section
                        className="wrapper-4weeks"
                        style={{
                            width: 'fit-content !important',
                        }}
                    >
                        <span
                            className="paragraph_4weeks"
                            style={{
                                width: 'fit-content !important',
                            }}
                        >
                            <h4>Con todas las suscripciones disfruta de:</h4>

                            <h5>
                                Acceso a las clases en DIRECTO pero quedan
                                ¡¡GUARDADAS!!, para hacerlas dónde y cuándo
                                quieras durante todo el mes.
                            </h5>

                            <h5>
                                Acceso a la comunidad Aguado Pilates para
                                mantenernos motivadas.
                            </h5>

                            <h5>Contacto directo con tu instructora Ana.</h5>

                            <h5>
                                Podrás cambiar de plan en cualquier momento.
                            </h5>

                            <p
                                style={{
                                    fontSize: '0.8rem',
                                }}
                            >
                                Todas las suscripciones se renuevan
                                automáticamente si no cancelas antes.
                            </p>
                        </span>
                    </section>
                </div>
            </main>
        </Wrapper>
    );
}
export default AllWorkoutsPage;
