import './menuMovil.css';
import './menu.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { BondsPage } from '../../pages/bonds/bonds';
import { aMenuItems } from '../../models/menu.model';
import AboutMePage from '../../pages/aboutMePage/aboutMePage';
import ContactPage from '../../pages/contactPage/contactPage';
import AllWorkoutsPage from '../../pages/workoutPage/allWorkoutsPage';

export function MenuMovil() {
    const optionsMenu: aMenuItems = [
        {
            path: 'workouts',
            label: 'Suscripciones',
            page: <AllWorkoutsPage />,
        },
        {
            path: 'bonos',
            label: 'Bonos',
            page: <BondsPage />,
            title: 'Bonos',
        },
        {
            path: 'sobremi',
            label: 'Sobre mí',
            page: <AboutMePage />,
            title: 'Sobre mí',
        },
        {
            path: 'contacto',
            label: 'Contacto',
            page: <ContactPage />,
            title: 'Contacto',
        },
    ];

    const [clicked, setClicked] = useState(false);
    function handleClick() {
        setClicked(!clicked);
    }

    if (clicked === true) {
        return (
            <>
                <img
                    role="button"
                    className="buttonMenu"
                    src="./img/icons/x.png"
                    alt="Button menu"
                    onClick={handleClick}
                />
                <div className="menuMovil">
                    <nav>
                        <ul className="menuList">
                            {optionsMenu.map((item) => (
                                <li className="menu__item" key={item.label}>
                                    <Link
                                        to={item.path}
                                        className="menu__link"
                                        onClick={handleClick}
                                    >
                                        <h3 className="menu__title">
                                            {item.label}
                                        </h3>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <a
                        className="wrapper_iconInstagram"
                        href="https://www.instagram.com/aguado.pilates/?hl=es"
                    >
                        <img
                            className="iconInstagram"
                            src="./img/icons/instagram.png"
                            alt="Instagram"
                        />
                    </a>
                </div>
            </>
        );
    } else {
        return (
            <>
                <img
                    role="button"
                    className="buttonMenu"
                    src="./img/icons/menuMobile.png"
                    alt="Button menu"
                    onClick={handleClick}
                />
            </>
        );
    }
}
