import styled from 'styled-components';

export const Wrapper = styled.div`
    .wrapper__challenge {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }

    .title__workout {
        font-size: 2rem;
        font-weight: 1100;
        margin: 0;
    }

    section {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .paragraph_workout {
        display: inline-block;
        max-width: 450px;
        margin-top: 0.8rem;
        text-align: initial;
        width: 100%;

        h4 {
            margin: 0;
        }
    }

    .containerWorkoutPage {
        text-align: center;
        margin: 1.5rem;
        margin-bottom: 4rem;
    }

    .workout {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .img {
        max-width: 450px;
        width: 100%;
    }

    .img_challenge {
        max-width: 525px;
        width: 100%;
    }

    .workout-line {
        gap: 4rem;
        margin-bottom: 3rem;
    }

    .button-custom {
        background-color: #4a4a4a;
        border-radius: 40px;
        color: white;
        border: none;
        width: 130px;
        height: 35px;
        cursor: pointer;
    }

    .button-custom:hover {
        background-color: #b9afa1;
    }

    .button-sold-out {
        background-color: red;
    }

    .button-sold-out:hover {
        background-color: red;
    }

    .data {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 2rem;
        margin-bottom: 1.5rem;
        gap: 0.5rem;
    }

    p {
        margin: 2px;
    }

    .workout-title {
        font-size: x-large;
        font-weight: 1000;
        color: black;
    }

    .workout-4weeks {
        display: flex;
        gap: 2rem;
    }

    .wrapper-4weeks {
        display: flex;
        justify-content: flex-start;
        margin: 1rem 3rem;
        width: fit-content !important;
    }

    .paragraph_4weeks {
        width: fit-content !important;
        text-align: initial;
        h4 {
            margin-bottom: 2rem;
            font-weight: 1000;
        }

        .tag {
            width: 300px;
            border-radius: 5px;
            padding: 1px;
            margin-bottom: 2rem;

            h2 {
                margin: 0;
                font-size: 1.2rem;
            }

            h3 {
                margin: 0;
                font-size: 1rem;
            }
        }
    }

    @media (max-width: 600px) {
        .wrapper-4weeks {
            margin: 0;
            margin-top: 2rem;
            width: fit-content !important;
        }

        .paragraph_4weeks {
            width: fit-content !important;
        }

        .title__workout {
            font-size: x-large;
        }

        .containerWorkoutPage {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-bottom: 1rem;
        }

        .workout-title {
            font-size: 1.3rem;
        }

        section {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        .wrapperWorkouts {
            width: 100%;
        }

        .workout {
            width: 100%;
        }

        .paragraph_workout {
            text-align: initial;
        }

        .workout-line {
            margin-bottom: 1rem;
            gap: 1rem;
        }

        .data {
            align-items: flex-start;
        }
    }

    @media (max-width: 800px) {
        .workout-4weeks {
            display: flex;
            flex-direction: column;
            width: fit-content !important;
        }

        .paragraph_4weeks {
            width: fit-content !important;
        }
    }

    @media (min-width: 900px) {
        .wrapper__challenge {
            display: flex;
            flex-direction: row;
        }
    }
`;
