import './contactPage.css';
import { T } from '../../text/index';
import { useEffect } from 'react';
import ReactGA from 'react-ga';

export function ContactPage() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <main className="containerContactPage">
            <h2 className="title__contact">Contacto</h2>
            <div className="wrapper__container">
                <div className="wrapperImage">
                    <img
                        className="imageContact"
                        src="./img/contact/contact.svg"
                        alt=""
                    />
                </div>
                <div className="wrapperContact">
                    <p className="paragraph__contact">{T.CONTACT_TEXT}</p>
                    <div className="wrapperContact__info">
                        <a href="https://www.instagram.com/aguado.pilates/?hl=es">
                            <div className="containerContactIcon">
                                <img
                                    className="iconContact"
                                    src="./img/icons/instagram.png"
                                    alt=""
                                />
                                <p className="paragraph">@aguado.pilates</p>
                            </div>
                        </a>

                        <a href="mailto:info@aguadopilates.com">
                            <div className="containerContactIcon">
                                <img
                                    className="iconEmail"
                                    src="./img/icons/email.png"
                                    alt=""
                                />
                                <p className="paragraph">
                                    info@aguadopilates.com
                                </p>
                            </div>
                        </a>
                        <div className="containerContactIcon">
                            <img
                                className="iconWhatssap"
                                src="./img/icons/wa.png"
                                alt=""
                            />
                            <p className="telephone">676267421</p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default ContactPage;
