const language = {
    BASIC_SUSCRIPCTION: 'SUSCRIPCIÓN BASIC',
    BEAT_YOUR_LAZINESS:
        'Vence tu pereza, estira tu MAT y no te pierdas nuestras clases ONLINE, creadas para ti ',
    CLASSES_ARE_SAVED: 'Las clases quedan grabadas',
    CONTACT_TEXT:
        'Para cualquier duda que necesites resolver, sugerencia que desees aportar, asesoramiento, propuestas, consejos o cualquier otra cosa que se te pueda ocurrir estoy al otro lado.',
    FEEL_HEALTHY: 'Siénte sana, fuerte y segura',
    FEEDBACKS: 'Feedbacks',
    FEEDBACK_1: '"BIENESTAR en toda la amplitud de la palabra"',
    FEEDBACK_2:
        '"Después de cada clase salgo NUEVA, consigo desconectar y me cambia la semana por completo"',
    FEEDBACK_3:
        '"Agradecida, feliz, encantada, no imaginas lo mucho que me inspiras"',
    FREE_PERSONALIZED_CLASS: 'CLASE PERSONALIZADA GRATUITA',
    DAYS_AND_TIMES: 'Días y hora: ',
    DURATION_CASS: 'Duración de la clase',
    HOME_TEXT:
        ' Te ofrecemos las herramientas que necesitas para crear el entrenamiento con el que siempre habias soñado, aquel que se adapta a tu objetivo, cualidades, prioridades y preferencias. Gracias a esta web conseguiras que tu entrenamiento se convierta en tu rutina de autocuidado. Conviertete en tu propio instructor. ¿Entrenamos?',
    LIVE: 'DIRECTO',
    MINUTES_30: '30 minutos',
    MINUTES_30_40: '30-40 minutos',
    MONTHLY_PAYMENT: 'PAGO MENSUAL ',
    ONE_HOUR_20_21: '1 hora (20 a 21h)',
    ONE_HOUR: '1 hora',
    ONE_MONTH: '1 mes',
    READY_TO_REACH_YOUR_BEST_VERSION:
        '¿PREPARADA PARA ALCANZAR TU MEJOR VERSIÓN?',
    RECEIVE_AS_A_GIFT: 'RECIBIR DE REGALO:',
    SEE_PLAN: 'VER PLAN',
    SOLD_OUT: 'AGOTADO',
    SPENDING_A_FEW_MINUTES:
        'Fortalece tu abdomen, mejora tu postura y consigue una cintura más estrecha',
    SUBSCRIBE: 'SUSCRIBIRSE',
    THERE_IS_THE_PERFECT_COMBO:
        'Existe el combo perfecto y esta en la práctica de estas dos disciplinas',
    THREE_CLASSES_WEEKLY_LIVE: '3 clases semanales en DIRECTO',
    TRY_7_DAYS_FREE: 'Prueba 7 días GRATIS',
    TWO_WEEKLY_CLASSES_LIVE: '2 clases semanales en DIRECTO',
    TWO_CLASSES_WEEKLY: '2 clases a la semana en ',
};

export default language;
