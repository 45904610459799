import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import './App.css';
import React from 'react';
import { Layout } from '../../core/layout/layout';
import { BondsPage } from '../../pages/bonds/bonds';
import { aMenuItems } from '../../models/menu.model';

function App() {
    const HomePage = React.lazy(() => import('../../pages/homePage/homePage'));

    const AboutMePage = React.lazy(
        () => import('../../pages/aboutMePage/aboutMePage')
    );
    const ContactPage = React.lazy(
        () => import('../../pages/contactPage/contactPage')
    );
    const AllWorkoutsPage = React.lazy(
        () => import('../../pages/workoutPage/allWorkoutsPage')
    );

    const TermsAndConditionsPage = React.lazy(
        () => import('../../pages/legalWarning/termsAndConditions')
    );

    const PrivacyPolicyPage = React.lazy(
        () => import('../../pages/legalWarning/privacyPolicy')
    );

    const options: aMenuItems = [
        {
            path: 'bonos',
            label: 'Bonos',
            page: <BondsPage />,
            title: 'Bonos',
        },
        {
            path: 'workouts',
            label: 'Workouts',
            page: <AllWorkoutsPage />,
        },
        { path: '', label: 'home', page: <HomePage />, title: 'Home' },
        {
            path: 'sobremi',
            label: 'sobre mí',
            page: <AboutMePage />,
            title: 'Sobre mí',
        },
        {
            path: 'contacto',
            label: 'contacto',
            page: <ContactPage />,
            title: 'Contacto',
        },
        {
            path: '*',
            label: '',
            page: <Navigate replace to="" />,
            title: '',
        },
        {
            path: 'terms-and-conditions',
            label: 'Terminos y condiciones',
            page: <TermsAndConditionsPage />,
            title: '',
        },
        {
            path: 'privacy-policy',
            label: 'Política de privacidad',
            page: <PrivacyPolicyPage />,
            title: '',
        },
    ];

    return (
        <Router>
            <Layout>
                <React.Suspense>
                    <React.StrictMode>
                        <Routes>
                            {options.map((item) => (
                                <Route
                                    key={item.label}
                                    path={item.path}
                                    element={item.page}
                                ></Route>
                            ))}
                        </Routes>
                    </React.StrictMode>
                </React.Suspense>
            </Layout>
        </Router>
    );
}

export default App;
