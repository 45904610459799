import { useEffect } from 'react';
import ReactGA from 'react-ga';

import './aboutMePage.css';
export function AboutMePage() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <>
            <main className="containerAboutMe">
                <h2 className="title__aboutMe">Hola, bienvenido</h2>
                <div className="wrapperAbout">
                    <img
                        className="imageBowl"
                        src="./img/aboutme/taza.svg"
                        alt="Imagen inspiración con taza"
                    />
                    <p className="paragraph__first">
                        Soy Ana, tengo 26 años y mi gran pasión por el
                        autocuidado me ha llevado a satisfacer mi curiosidad y
                        mis ganas de aprender con continuas formaciones como
                        pilates, hipopresivos, ballet fit y el grado en Ciencia
                        y Tecnología de los Alimentos.
                    </p>
                </div>
                <div className="wrapperAbout">
                    <p className="paragraph__second">
                        Pero de lo que mas he aprendido ha sido de mi
                        experiencia como instructora durante mis 3 años
                        impartiendo clases donde gracias a vosotras, las que
                        formáis parte de este equipo, me habéis retado a
                        superarme personal y profesionalmente. La belleza de sus
                        cuerpos, su constancia y fuerza son mi inspiración, lo
                        que me motiva a despertar cada día con ganas de crear
                        nuevas secuencias, nuevos ejercicios que os hagan llegar
                        al siguiente nivel retando vuestra fuerza, equilibrio,
                        concentración y flexibilidad.
                    </p>
                    <img
                        className="imageWorking"
                        src="./img/aboutme/rojo.svg"
                        alt="Imagen Ana sonriendo trabajando"
                    />
                </div>
                <div className="wrapperAbout">
                    <img
                        className="imageStretchingDestop"
                        src="./img/aboutme/estirando.svg"
                        alt="Imagen estirando"
                    />
                    <p className="paragraph__third">
                        Apasionada de la nutrición, el mundo de la cocina
                        nutritiva y de todo lo que me permite encontrar ese
                        equilibrio donde se esconde el bienestar, abro esta
                        plataforma para aportaros herramientas para cuidar cada
                        dia mas de nosotras mismas. Actualmente podéis
                        encontrarme en Madrid pero soy de un pueblo de Toledo,
                        Puente del Arzobispo, de ahi mi especial cariño hacia
                        todo lo natural, lo autentico, lo real, lo ARTESANAL. De
                        familia ceramista y enamorada del mundo del menaje adoro
                        reunir a mis alumnas y recibir a mi familia y amigos con
                        mesas que destaquen por la presencia en ellas de piezas
                        que esconden historias., si quieres conocer más sobre
                        esto visita el apartado de eventos.
                    </p>
                    <img
                        className="imageStretchingMobile"
                        src="./img/aboutme/estirando.svg"
                        alt="Imagen estirando"
                    />
                </div>
                <div className="wrapperAbout">
                    <p className="paragraph__fourth">
                        En fin, como habrás podido comprobar a pesar de mi
                        temprana edad soy una mujer ambiciosa, apasionada,
                        soñadora pero una mujer como una de vosotras simplemente
                        con un sueño que deseo alcanzar y que poco a poco con
                        trabajo y esfuerzo lucho cada día por ello. Estoy
                        deseando que formes parte de mi equipo, nos vemos al
                        otro lado :)
                    </p>

                    <img
                        className="imageHipos"
                        src="./img/aboutme/rojohipos.svg"
                        alt="Imagen hipopresivos"
                    />
                </div>
            </main>
        </>
    );
}

export default AboutMePage;
